import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/layout";
import useCareers from "../hooks/dataHooks/useCareers";

const Modules = loadable(() => import("../components/modules"));
const CareersPosition = loadable(() => import("../components/CareersPosition/CareersPosition"));

const Careers = (props) => {

    const data = useCareers();


    return (
        <Layout>
            <div className="layout-padding-top"></div>
            <Modules {...props} />
            <CareersPosition data={data} />
        </Layout>
    )
}

export default Careers