import { graphql, useStaticQuery } from "gatsby"

const useTeams = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(
        filter: {status: {eq: "publish"}, categories: {nodes: {elemMatch: {slug: {eq: "careers"}}}}}
      ) {
        nodes {
          uri
          title
          status
          slug
          categories {
            nodes {
              name
            }
          }
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
          ACF_careers {
            location
            salary
          }
        }
      }
    }
  `)

  return data.allWpPost.nodes
}

export default useTeams